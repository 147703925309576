import React, { useState } from "react"
import GoogleMapReact from "google-map-react"
import Marker from "./Marker"

const SimpleMap = ({ pins }) => {
  const getMapOptions = maps => {
    return {
      disableDefaultUI: false,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "on" }],
        },
      ],
    }
  }

  const [center, setCenter] = useState({ lat: 51.2585753, lng: -114.0172511 })
  const [zoom, setZoom] = useState(16)
  return (
    <div style={{ height: "75rem", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_API_KEY }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={getMapOptions}
      >
        {pins.acf._coo_shm_pins.map((pin, index) => {
          return (
            <Marker
              key={index}
              lat={pin.lat}
              lng={pin.lng}
              name={pin.title}
              description={pin.description}
              link={pin.google_map_link}
              type={pin.shosc}
            />
          )
        })}
      </GoogleMapReact>
    </div>
  )
}

export default SimpleMap
