import React from "react"
import styled from "styled-components"
import {
  standardWrapper,
  H1Green,
  H3Black,
  B1Black,
} from "../../styles/helpers"

const ShowHomeIntroSection = styled.section`
  padding: 4rem 0;

  .wrapper {
    ${standardWrapper}
  }

  .title {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;

    h2 {
      ${H1Green};
    }

    h3 {
      ${H3Black};
    }
  }

  .content {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    p {
      ${B1Black};
    }
  }
`

const ShowHomeIntro = ({ showHomesIntro }) => {
  return (
    <ShowHomeIntroSection>
      <div className="wrapper">
        <div className="title">
          <h2>{showHomesIntro.acf._coo_ssintro_title}</h2>
          <h3>{showHomesIntro.acf._coo_ssintro_sub_title}</h3>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: showHomesIntro.acf._coo_sscontent_content,
          }}
        />
      </div>
    </ShowHomeIntroSection>
  )
}

export default ShowHomeIntro
