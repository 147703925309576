import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors, B1Black, medWrapper, buttonThree } from "../../styles/helpers"

const ShowHomesContentSection = styled.section`
  .wrapper {
    ${medWrapper};
    padding-top: 0;
  }

  .homeContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const ShowHomeItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  border-top: 0.25rem solid ${colors.grey};

  .item-content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(80%);
      padding: 0 2rem;
    }

    @media (min-width: 1025px) {
      width: calc(85%);
      padding: 0 2rem;
    }

    &__builderLink {
      width: 100%;
      margin-top: 2.5rem;
      text-align: left;
      a {
        ${buttonThree};
      }
    }

    &__title {
      h3 {
        ${B1Black};
        margin: 0;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    &__google {
      a {
        ${B1Black};
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
          color: ${colors.colorSecondary};
        }
      }
    }
  }

  .item-logo {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(20%);
      padding: 0 2rem;
    }

    @media (min-width: 1025px) {
      width: calc(15%);
      padding: 0 2rem;
    }

    &__container {
      width: 100%;
      max-width: 25rem;
      margin: auto;
      text-align: center;
    }
  }
`

const Locations = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`

const Location = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 5rem;

  .locImage {
    @media (min-width: 768px) {
      width: calc(35% - 2rem);
      margin-right: 2rem;
    }
  }

  .locContent {
    @media (min-width: 768px) {
      width: calc(65% - 2rem);
      margin-left: 2rem;
    }

    &__title {
      p {
        ${B1Black};
        font-weight: bold;
      }
    }

    &__content {
      p {
        ${B1Black};
      }
    }
  }
`

const ShowHomesContent = ({ showHomes }) => {
  return (
    <ShowHomesContentSection>
      <div className="wrapper">
        <div className="homeContainer">
          {showHomes.acf._coo_shsc_items.map((home, index) => {
            const params = home.title.split(" ").join("").toLowerCase()


            return (
              <ShowHomeItem key={index}>
                <div className="item-logo">
                  <div className="item-logo__container">
                    <Img
                      fluid={home.logo.localFile.childImageSharp.fluid}
                      alt={home.logo.alt_text}
                    />
                  </div>
                  <div className="item-content__builderLink">
                    <Link to={`/show-homes/builders-info?builder=${params}`}>
                      About Builder
                    </Link>
                  </div>
                </div>

                <div className="item-content">
                  <div className="item-content__title">
                    <h3>{home.title}</h3>
                  </div>

                  <Locations className="item-content__locations">
                    {home.locations.map((loc, index) => {
                      return (
                        <Location>
                          <div className="locImage" key={index}>
                            <Img
                              fluid={loc.image.localFile.childImageSharp.fluid}
                              alt={loc.image.alt_text}
                            />
                          </div>
                          <div className="locContent">
                            <div className="locContent__title">
                              <p>{loc.title}</p>
                            </div>
                            <div
                              className="locContent__content"
                              dangerouslySetInnerHTML={{ __html: loc.content }}
                            />
                            {loc.google_map_link &&
                             <div className="item-content__google">
                               <a target="_blank" href={loc.google_map_link}>
                                 <span>&gt;</span> View Google MAps
                               </a>
                             </div>
                            }
                          </div>
                        </Location>
                      )
                    })}
                  </Locations>
                </div>
              </ShowHomeItem>
            )
          })}
        </div>
      </div>
    </ShowHomesContentSection>
  )
}

export default ShowHomesContent
