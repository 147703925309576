import React, { useState } from "react"
import styled from "styled-components"
import {
  colors,
  fonts,
  fontSizer,
  B1Black,
  buttonThree,
} from "../../styles/helpers"

const MarkerStyles = styled.div`
  display: block;
  position: relative;
  width: 100%;

  .markerPin {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${props =>
      props.type === "showHome" ? colors.colorTertiary : colors.colorShad};
    border: 0.15rem solid ${colors.white};
    border-radius: 50%;

    &::before {
      color: ${colors.white};
      font-family: ${fonts.fontAwesome};
      font-size: 1.25rem;
      line-height: 1.5;
      content: "\f015";
    }
  }

  .markerContent {
    position: absolute;
    bottom: 130%;
    left: 0;
    width: 25rem;
    padding: 1.5rem;
    margin: auto;
    transition: all 0.3s ease;
    background: ${colors.white};
    border-radius: 0.5rem;
    z-index: 2;
    opacity: ${props => (props.isActive ? "1" : "0")};
    visibility: ${props => (props.isActive ? "visible" : "hidden")};

    &__title {
      margin-bottom: 1.5rem;
      padding-bottom: 1rem;
      border-bottom: solid 0.1rem ${colors.grey};
      p {
        ${B1Black};
        margin: 0;
      }
    }

    &::before {
      position: absolute;
      display: block;
      bottom: -0.5rem;
      left: 1rem;
      width: 1rem;
      height: 1rem;
      background: ${colors.white};
      transform: rotate(-45deg);
      content: "";
      z-index: 1;
    }

    &__description {
      p {
        ${fontSizer(1.2, 1.4, 76.8, 150, 1.6)};
      }
    }

    &__directions {
      a {
        ${buttonThree};
      }
    }
  }
`

const Marker = props => {
  const { name, description, type, link } = props
  const [isActive, toggleActive] = useState(false)

  return (
    <MarkerStyles
      onMouseEnter={() => {
        toggleActive(true)
      }}
      onMouseLeave={() => {
        toggleActive(false)
      }}
      isActive={isActive}
      type={type}
    >
      <div className="markerContent">
        <div className="markerContent__title">
          <p>{name}</p>
        </div>
        <div
          className="markerContent__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="markerContent__directions">
          <a target="_blank" href={link}>
            Get Directions
          </a>
        </div>
      </div>
      <div className="markerPin" />
    </MarkerStyles>
  )
}

export default Marker
