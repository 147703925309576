import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import TopQuote from "../components/ShowHomes/TopQuote"
import ShowHomesContent from "../components/ShowHomes/ShowHomesContent"
import ShowMap from "../components/ShowHomes/ShowMap"
import ShowHomeIntro from "../components/ShowHomes/ShowHomeIntro"
import ShowHomeHours from "../components/ShowHomes/ShowHomeHours"

const ShowHomes = props => {
  const {
    seoInfo,
    topQuote,
    showMap,
    showHomesIntro,
    showHomes,
    showHomeHours,
  } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <TopQuote topQuote={topQuote} />
      <ShowHomesContent showHomes={showHomes} />
      <ShowMap pins={showMap} />
      {/* <ShowHomeIntro showHomesIntro={showHomesIntro} /> */}
      <ShowHomeHours showHours={showHomeHours} />
    </Layout>
  )
}

export const showHomesQuery = graphql`
  query showHomesPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topQuote: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_toqu_title
        _coo_toqu_content
      }
    }

    showMap: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_shm_pins {
          lat
          lng
          shosc
          title
          description
          google_map_link
        }
      }
    }

    showHomesIntro: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_ssintro_title
        _coo_ssintro_sub_title
        _coo_sscontent_content
      }
    }

    showHomes: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_shsc_items {
          title
          logo {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          builder_website
          locations {
            title
            content
            google_map_link
            image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    showHomeHours: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_sshr_content
        _coo_sshr_fyl {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_sshr_amap {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_sshr_lic {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default ShowHomes
