import React from "react"
import styled from "styled-components"
import { B2Black, standardWrapper, colors } from "../../styles/helpers"

const ShowHomeHoursSection = styled.section`
  padding: 3rem 0;
  background-color: rgba(197, 231, 222, 0.5);

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    h2 {
      ${B2Black};
      margin: 0;
      text-transform: uppercase;
    }
  }

  .content {
    width: 100%;
    p {
      ${B2Black};
    }
  }
`

const ShowHomeHours = ({ showHours }) => {
  return (
    <ShowHomeHoursSection>
      <div className="wrapper">
        <div className="title">
          <h2>Show Home Hours</h2>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: showHours.acf._coo_sshr_content }}
        />
      </div>
    </ShowHomeHoursSection>
  )
}

export default ShowHomeHours
